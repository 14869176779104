<template>
  <div class="flex flex-wrap gap-5 flex-1">
    <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
      <div
        class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
      >
        <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
          <div
            class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
          >
            <div v-html="EARNINGS_ICON" class="text-white"></div>
          </div>
          <div
            class="flex flex-col flex-1 shrink self-stretch my-auto whitespace-nowrap basis-6"
          >
            <div
              class="text-sm font-medium leading-none text-gray-500 text-left"
            >
              Earnings
            </div>
            <div
              class="gap-2 self-start text-2xl mt-2 font-semibold leading-none text-zinc-800"
            >
              ${{ totalEarnings / 100 || 0 }} (USD)
            </div>
          </div>
        </div>
        <BaseButton
          type="button"
          @click="navigateToEarning"
          class="flex flex-col justify-center items-start p-4 w-full text-sm font-medium leading-none text-indigo-600 bg-gray-50 text-left"
        >
          <span class="self-stretch">See Details</span>
        </BaseButton>
      </div>
    </article>
    <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
      <div
        class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
      >
        <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
          <div
            class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
          >
            <div v-html="PROGRAMS_ICON" class="text-white"></div>
          </div>
          <div class="flex flex-col flex-1 shrink self-stretch my-auto basis-6">
            <div
              class="text-sm font-medium leading-none text-gray-500 text-left"
            >
              Total Programs
            </div>
            <div
              class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
            >
              {{ totalPrograms }}
            </div>
          </div>
        </div>
      </div>
    </article>
    <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
      <div
        class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
      >
        <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
          <div
            class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
          >
            <div v-html="PHONE_ICON" class="text-white"></div>
          </div>
          <div class="flex flex-col flex-1 shrink self-stretch my-auto basis-6">
            <div
              class="text-sm font-medium leading-none text-gray-500 text-left"
            >
              Upcoming Meetings
            </div>
            <div
              class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
            >
              {{ bookingCountString }}
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script lang="ts">
import {
  EARNINGS_ICON,
  PROGRAMS_ICON,
  PHONE_ICON,
} from "@/assets/svg/expert-overview/svgConstants";
import BaseButton from "../shared/BaseButton.vue";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { useRouter } from "vue-router";

const GET_BOOKINGS = gql`
  query GetBookings($totalBookings: Boolean) {
    listBooking(totalBookings: $totalBookings) {
      bookings {
        id
        day
        startTime
        endTime
      }
      totalCount
    }
  }
`;

const GET_EXPERT_EARNINGS = gql`
  query getExpertEarnings {
    getExpertEarnings {
      escrows {
        availableBalance
        pendingBalance
        totalEarnings
      }
    }
  }
`;

export default defineComponent({
  name: "InformationBlocks",
  props: {
    totalPrograms: {
      type: String,
      required: true,
    },
    handleError: {
      type: Function as PropType<(arg0: string) => void>,
      required: true,
    },
  },
  components: {
    BaseButton,
  },
  setup(props) {
    const totalBookings = ref(0);
    const totalEarnings = ref(0);
    const router = useRouter();

    const navigateToEarning = () => {
      router.push({ name: "ExpertEarnings" });
    };

    const { result: bookingsResult, error: bookingsError } = useQuery(
      GET_BOOKINGS,
      {
        totalBookings: true,
      },
      {
        errorPolicy: "all",
      }
    );

    watch(
      () => bookingsResult.value,
      (newValue) => {
        if (newValue?.listBooking?.totalCount) {
          totalBookings.value = newValue.listBooking.totalCount;
        }
        console.log("bookings result", newValue);
      },
      { immediate: true }
    );

    watch(
      () => bookingsError.value,
      (queryError) => {
        if (queryError) {
          // dashboardError.value = queryError.message;
          props.handleError(queryError.message);
          console.log("error is ", queryError.message);
        }
      },
      { immediate: true }
    );

    const getEarnings = () => {
      const { onResult, onError } = useQuery(GET_EXPERT_EARNINGS, {});

      onResult((result) => {
        console.log(result);
        if (result) {
          if (result?.data?.getExpertEarnings?.escrows) {
            console.log("Earnings ", result.data.getExpertEarnings);
            totalEarnings.value =
              result.data.getExpertEarnings.escrows.totalEarnings;
          }
        }
      });

      onError((error) => {
        if (error) {
          console.error(error);
          // errorMessage.value = error.message;
          props.handleError(error.message);
          console.log("error is ", error.message);
        }
      });
    };

    onMounted(() => {
      getEarnings();
    });

    const bookingCountString = computed(() => {
      const count = totalBookings.value;
      return count > 9 ? String(count) : `0${count}`;
    });

    return {
      PHONE_ICON,
      PROGRAMS_ICON,
      EARNINGS_ICON,
      bookingCountString,
      totalEarnings,
      navigateToEarning,
    };
  },
});
</script>
