<template>
  <article class="flex flex-col w-[32%] max-md:ml-0 max-md:w-full">
    <div
      class="flex flex-col grow justify-center text-left p-6 w-full bg-white rounded border border-gray-300 border-solid max-md:px-5 max-md:mt-6"
    >
      <div class="flex flex-col w-full">
        <div class="flex gap-5 items-center w-full">
          <div
            class="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0"
          >
            <div class="flex flex-col w-full">
              <h2
                class="gap-2 max-w-full text-2xl font-semibold leading-none text-gray-900"
              >
                Shop Gift Card
              </h2>
              <p class="text-sm font-medium leading-none text-gray-500 mt-1">
                Instant
              </p>
            </div>
          </div>
        </div>
        <div class="flex flex-col mt-5 w-full">
          <div
            class="flex flex-col justify-center px-2.5 py-4 w-full bg-gray-50 rounded-lg"
          >
            <div class="flex gap-4 items-center w-full">
              <div
                class="flex h-full justify-center items-center self-stretch px-2.5 my-auto bg-violet-50 rounded-xl"
              >
                <img
                  loading="lazy"
                  src="../../../assets/expert-dashboard/gift-card.svg"
                  class="object-contain self-stretch my-auto aspect-[1.61] min-w-[74px]"
                  alt="Charity icon"
                />
              </div>
              <div class="flex flex-col items-start self-stretch my-auto">
                <div class="flex gap-2 items-end max-w-full">
                  <div class="flex flex-col flex-1 shrink w-full basis-0">
                    <h3 class="text-base font-medium text-gray-900">
                      Coming Soon
                    </h3>
                    <p class="mt-2 text-sm leading-5 text-gray-500">
                      Charity is the act of giving help, Slashexpert give money,
                      goods or time to those who need it
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ShopGiftCard",
});
</script>
