<template>
  <div
    class="flex flex-wrap gap-5 justify-between mt-16 w-full font-medium max-md:mt-10 max-md:max-w-full"
  >
    <h2 class="self-start text-lg leading-loose text-zinc-800">
      Payments History
    </h2>
    <div></div>

    <div
      class="mt-2 w-full overflow-auto max-w-full max-h-[200px] overflow-y-auto"
    >
      <table class="min-w-full border-collapse bg-white">
        <thead>
          <tr class="bg-gray-50">
            <th
              v-for="(header, index) in tableHeaders"
              :key="index"
              class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(payment, index) in transactions"
            :key="index"
            :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            class="text-left"
          >
            <td class="px-6 py-4 text-sm text-gray-900">
              {{ createAt(payment.createdAt) }}
            </td>
            <td class="px-6 py-4 text-sm text-gray-500">
              {{ payment.activity }}
            </td>
            <td class="px-6 py-4 text-sm text-gray-500 whitespace-normal">
              {{ payment.description }}
            </td>
            <td class="px-6 py-4 text-sm text-gray-500">
              {{ payment.status.toUpperCase() }}
            </td>
            <td class="px-6 py-4 text-sm text-gray-500">
              ${{ payment.amount }}
            </td>
          </tr>
          <tr v-if="moreTransactions">
            <td colspan="5" class="">
              <BaseButton
                @click="loadMore"
                class="px-6 py-2 rounded-md text-sm bg-primary-purple/10 text-primary-purple"
                >Load More</BaseButton
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, PropType, defineComponent } from "vue";
import { Payment, Transaction } from "@/types/earnings/types";
import { CALENDAR_ICON } from "@/assets/svg/expert-overview/svgConstants";
import BaseButton from "../shared/BaseButton.vue";

export default defineComponent({
  name: "PaymentsHistory",
  components: {
    BaseButton,
  },
  props: {
    transactions: {
      type: Array as PropType<Transaction[]>,
      required: true,
    },
    moreTransactions: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    loadMoreTransactions: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const tableHeaders = ref<string[]>([]);
    const payments = ref<Payment[]>([]);
    const selectedDate = ref<Date | null>(null);
    const customPosition = () => ({ top: 0, left: 0 });

    const showDatePicker = ref(false);

    tableHeaders.value = [
      "Date",
      "Activity",
      "Description",
      "Status",
      "Amount",
    ];

    const createAt = (date: string) => {
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString();
    };

    const loadMore = () => {
      props.loadMoreTransactions();
    };

    return {
      tableHeaders,
      payments,
      CALENDAR_ICON,
      showDatePicker,
      selectedDate,
      customPosition,
      createAt,
      loadMore,
    };
  },
});
</script>
