// src/apollo.js
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  ServerError,
} from "@apollo/client/core";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { setContext } from "@apollo/client/link/context";
import { provideApolloClient } from "@vue/apollo-composable";
import { useAuthStore } from "@/stores/auth";
import { onError } from "@apollo/client/link/error";

// Create the upload link for handling file uploads (adjust if needed)
const uploadLink = createUploadLink({
  uri: process.env.VUE_APP_API_TARGET,
});

// Authentication link to include token in headers
const authLink = setContext((_, { headers }) => {
  const authStore = useAuthStore();
  const token = authStore.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// handle GraphQL errors globally (e.g. for token expiration)
const errorLink = onError(({ networkError }) => {
  if (networkError) {
    if ("statusCode" in networkError && networkError.statusCode === 401) {
      const serverError = networkError as ServerError;
      let errorMessage = "";
      if (serverError?.result && typeof serverError.result !== "string") {
        errorMessage = serverError.result.errors + " Please login.";
      } else {
        errorMessage = "Please login.";
      }
      window.location.href = `/signin?error=${errorMessage}`;
      return;
    }
  }
});

// Combine the links in the correct order: error handling -> authentication -> file upload
const link = ApolloLink.from([errorLink, authLink, uploadLink]);

// Initialize Apollo Client with the combined link
const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: "all",
    },
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
});

export function setupApolloClient() {
  provideApolloClient(apolloClient);
}
