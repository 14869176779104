import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "self-center px-1 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col justify-center self-stretch my-auto cursor-pointer", { 'text-indigo-600': _ctx.isActive, 'text-gray-500': !_ctx.isActive }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleClick && _ctx.handleClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.label), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["flex w-full", { 'bg-indigo-500': _ctx.isActive, 'bg-transparent': !_ctx.isActive }]),
      style: {"min-height":"2px"}
    }, null, 2)
  ], 2))
}