<template>
  <main class="flex flex-col min-h-screen bg-gray-50">
    <section
      class="flex-grow relative flex flex-col justify-center items-center px-16 py-20 w-full max-md:px-5 max-md:max-w-full"
    >
      <img
        loading="lazy"
        src="../assets/shared/background.png"
        alt=""
        class="absolute inset-0 w-full h-full object-cover"
      />
      <div class="flex relative flex-col mb-32 max-w-full max-md:mb-10">
        <img
          loading="lazy"
          src="../assets/shared/experts-text.png"
          alt="Company logo"
          class="self-center max-w-full aspect-[4]"
        />
        <div
          class="flex flex-col justify-center px-6 py-8 mt-16 bg-white rounded-md border border-solid shadow-sm max-md:px-5 max-md:mt-10 text-left"
          v-if="resetInstructionNotSent"
        >
          <h1
            class="self-stretch text-3xl font-semibold leading-9 text-center text-zinc-800"
          >
            Forget Password
          </h1>
          <p
            class="self-stretch text-base leading-6 text-center text-neutral-700 mt-3"
          >
            No worries, we'll send you reset instructions
          </p>
          <!-- Error Message Section -->
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          >
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <img
                src="../assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
          <!-- Error Message Section -->
          <form @submit.prevent="handleSubmit" class="mt-3">
            <BaseLabel
              forAttr="email"
              text="Email"
              class="mt-6 text-sm font-medium leading-5 text-zinc-800"
            />
            <BaseInput
              type="email"
              id="email"
              v-model="email"
              placeholder="you@gmail.com"
              className="px-3.5 py-2.5 mt-1 w-full text-sm leading-5 text-gray-500 bg-white rounded-md border border-gray-300 border-solid shadow-sm"
              required
            />
            <BaseButton
              type="submit"
              className="px-4 py-2.5 mt-6 w-full text-white bg-violet-600 rounded-md shadow-sm"
              :disabled="disabled"
              ><span v-if="disabled">Sending...</span>
              <span v-else>Reset Password</span>
            </BaseButton>
          </form>
          <div
            class="flex justify-center items-center px-16 mt-6 font-medium text-gray-500 max-md:px-5"
          >
            <router-link to="/" class="flex items-center gap-1.5">
              <i
                class="fas fa-arrow-left text-violet-600 shrink-0 w-5 aspect-square"
              ></i>
              <span class="relative -top-0.5">Back to Login</span>
            </router-link>
          </div>
        </div>
        <div class="flex flex-col mb-44 max-w-full max-md:mb-10" v-else>
          <article
            class="flex flex-col justify-center px-16 py-8 mt-14 bg-white rounded-md border border-solid shadow-sm max-md:px-5 max-md:mt-10 w-full"
          >
            <h1 class="text-3xl font-semibold leading-9 text-zinc-800">
              Check your email
            </h1>
            <p class="mt-3 text-base leading-6 text-neutral-700">
              We sent a verification link to<br />
              <span class="font-medium">{{ email }}</span>
            </p>
          </article>
        </div>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { defineComponent, ref } from "vue";
import axios, { AxiosError } from "axios";
import { ErrorResponse } from "@/types/interfaces";

export default defineComponent({
  name: "ForgetPassword",
  components: {
    BaseLabel,
    BaseInput,
    BaseButton,
  },
  setup() {
    const email = ref("");
    const errorMessage = ref<string | null>(null);
    const disabled = ref(false);
    const resetInstructionNotSent = ref(true);

    const handleSubmit = async () => {
      disabled.value = true;
      try {
        const { url, data, config } = getForgotPasswordRequestParams(
          email.value
        );
        const response = await axios.post(url, data, config);
        disabled.value = false;
        if (response.status === 200) {
          resetInstructionNotSent.value = false;
        } else {
          errorMessage.value = "Something went wrong, please try again.";
        }
      } catch (error) {
        handleError(error as AxiosError<ErrorResponse>);
      } finally {
        disabled.value = false;
      }
    };

    const getForgotPasswordRequestParams = (email: string) => {
      return {
        url: process.env.VUE_APP_FORGOT_PASSWORD,
        data: {
          user: {
            email: email,
          },
        },
        config: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      };
    };

    const handleError = (error: AxiosError<ErrorResponse>) => {
      if (error.response) {
        const errorData = error.response.data;
        if (typeof errorData === "string") {
          errorMessage.value = errorData;
        } else if (Array.isArray(errorData.errors)) {
          errorMessage.value = errorData.errors.join(", ");
        } else if (typeof errorData.errors === "string") {
          errorMessage.value = errorData.errors;
        } else {
          errorMessage.value = "An unexpected error occurred.";
        }
      } else {
        errorMessage.value = "An unexpected error occurred.";
      }
    };

    return {
      email,
      handleSubmit,
      errorMessage,
      disabled,
      resetInstructionNotSent,
    };
  },
});
</script>
