<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex bg-black bg-opacity-50 items-center justify-center z-50"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
      <button
        class="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        @click="$emit('update:isVisible', false)"
      >
        ✕
      </button>
      <div class="flex flex-col gap-3">
        <div class="flex flex-col gap-1">
          <span
            class="text-gray-800 text-base font-semibold leading-6 text-left"
            >Profile Submitted</span
          >
          <span class="text-left text-gray-500 text-sm font-medium leading-4"
            >Team {{ programName }} will review it in 2-3 business days.</span
          >
        </div>
        <div class="flex flex-col gap-1 justify-center items-center">
          <BaseButton
            type="button"
            class="bg-primary-purple max-w-max text-white font-medium px-4 py-2 rounded-md"
            @click="$router.push('/expert/dashboard')"
            >Dashboard</BaseButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "JoinRequestSubmittedModal",
  components: {
    BaseButton,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    programName: {
      type: String,
      required: true,
    },
  },
});
</script>
