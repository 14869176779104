<template>
  <div
    class="flex flex-col justify-center self-stretch my-auto cursor-pointer"
    :class="{ 'text-indigo-600': isActive, 'text-gray-500': !isActive }"
    @click="handleClick"
  >
    <div class="self-center px-1 pb-4">{{ label }}</div>
    <div
      class="flex w-full"
      :class="{ 'bg-indigo-500': isActive, 'bg-transparent': !isActive }"
      style="min-height: 2px"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "NavBar",
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    activeTab: {
      type: String,
      default: "",
    },
  },
  emits: ["update:activeTab"],
  setup(props, { emit }) {
    const isActive = computed(() => props.activeTab === props.value);

    const handleClick = () => {
      emit("update:activeTab", props.value);
    };

    return {
      isActive,
      handleClick,
    };
  },
});
</script>
