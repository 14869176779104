EXPERT LOGOUT FUnCTON on Navabr

<template>
  <nav
    class="flex flex-wrap gap-5 justify-between px-7 py-3 w-full max-md:px-5 max-md:max-w-full"
  >
    <div
      class="flex flex-wrap gap-10 text-sm font-medium leading-none text-gray-600 max-md:max-w-full"
    >
      <div class="mt-2">
        <div v-html="COMPANY_LOGO"></div>
      </div>
      <div class="flex flex-auto gap-5 overflow-x-auto whitespace-nowrap">
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              class="flex gap-2"
              type="button"
              @click="navigateToDashboard"
            >
              <div
                v-html="DASHBOARD_ICON"
                :class="{ 'text-primary-purple': activeTab == 0 }"
              ></div>
              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 0 }"
                >Dashboard</span
              >
            </BaseButton>
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              type="button"
              class="flex gap-2"
              @click="navigateToExpertise"
            >
              <div
                v-html="EXPERTISE_ICON"
                :class="{ 'text-primary-purple': activeTab == 1 }"
              ></div>
              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 1 }"
                >My Expert Programs</span
              ></BaseButton
            >
          </div>
        </div>
        <div
          class="flex overflow-hidden items-center py-2 pr-3 pl-2 whitespace-nowrap rounded-md"
        >
          <div class="flex gap-3 items-center self-stretch my-auto">
            <BaseButton
              type="button"
              class="flex gap-2"
              @click="navigateToEarning"
            >
              <div
                v-html="EARNINGS_ICON"
                :class="{ 'text-primary-purple': activeTab == 2 }"
              ></div>

              <span
                class="self-stretch my-auto"
                :class="{ 'text-primary-purple': activeTab == 2 }"
                >My Earning</span
              >
            </BaseButton>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-4 self-start mt-1 relative">
      <div class="flex" @click="toggleDropdown" id="userProfile">
        <div
          class="flex z-10 gap-3 items-center mr-0 font-medium cursor-pointer"
        >
          <div
            v-if="authStore?.profileInformation.profilePicture"
            class="relative w-9 h-9 overflow-hidden rounded-full flex items-center justify-center"
          >
            <img
              loading="lazy"
              :src="profilePictureUrl"
              alt="User profile picture"
              class="w-full h-full object-cover"
            />
            <div class="absolute inset-0"></div>
          </div>
          <div v-else class="flex flex-col">
            <div
              class="flex items-center justify-center w-8 h-8 bg-primary-purple text-white rounded-full"
            >
              {{ initials() }}
            </div>
          </div>
          <div class="flex flex-col self-stretch my-auto">
            <div class="text-sm leading-none text-gray-700">
              {{ authStore?.firstName }} {{ authStore?.lastName }}
            </div>
            <div class="text-xs leading-none text-gray-500">
              {{ email }}
            </div>
          </div>
        </div>
      </div>

      <!-- Dropdown Menu -->
      <div
        v-show="showDropdown"
        class="absolute right-0 mt-8 bg-white border border-gray-200 rounded-md shadow-lg p-3 w-36"
      >
        <div class="flex flex-col gap-2 text-left">
          <BaseButton
            type="button"
            :disabled="disableLogout"
            class="w-full text-left flex items-center gap-2 account-options-btns"
            @click="goToProfile"
          >
            <div v-html="PERSON_ICON" class="text-gray-600"></div>
            <span class="text-gray-500 font-sans text-sm font-medium leading-6"
              >Profile
            </span>
          </BaseButton>
          <BaseButton
            type="button"
            :disabled="disableLogout"
            class="w-full text-left flex items-center gap-3 account-options-btns"
            @click="logout"
          >
            <div v-html="LOGOUT_ICON" class="text-red-600 pl-1"></div>
            <span class="text-gray-500 font-sans text-sm font-medium leading-6"
              >Logout
            </span>
          </BaseButton>
        </div>
      </div>
    </div>
    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <!-- Icon Container -->
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <!-- Custom Image Icon -->
        <img
          src="../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage.slice(0, 60) }}</p>
    </div>
  </nav>
</template>

<script lang="ts">
import router from "@/router";
import {
  COMPANY_LOGO,
  LOGOUT_ICON,
  PERSON_ICON,
} from "@/assets/svg/shared/svgConstants";
import {
  EARNINGS_ICON,
  EXPERTISE_ICON,
  DASHBOARD_ICON,
} from "@/assets/svg/expert-overview/svgConstants";
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import BaseButton from "../shared/BaseButton.vue";
import { useAuthStore } from "@/stores/auth";
import axios from "axios";
import { useExpertProgramsStore } from "@/stores/expertPrograms";

export default defineComponent({
  name: "ExpertiseNavbar",
  components: {
    BaseButton,
  },
  props: {
    activeTab: Number,
  },
  setup() {
    const authStore = useAuthStore();
    const programsStore = useExpertProgramsStore();
    const showDropdown = ref(false);
    const disableLogout = ref(false);
    const errorMessage = ref("");

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const navigateToDashboard = () => {
      router.push({ name: "ExpertDashboard" });
    };

    const navigateToExpertise = () => {
      router.push({ name: "ExpertExpertise" });
    };

    const navigateToEarning = () => {
      router.push({ name: "ExpertEarnings" });
    };

    const getLogoutParams = () => {
      return {
        url: process.env.VUE_APP_LOGOUT_ENDPOINT,
        data: {
          user: authStore.token,
        },
        config: {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authStore.token}`,
          },
        },
      };
    };

    const logout = async () => {
      try {
        const logoutParams = getLogoutParams();
        const response = await axios.delete(logoutParams.url, {
          data: logoutParams.data,
          ...logoutParams.config,
        });

        if (response.status === 200) {
          programsStore.clearStore();
          authStore.clearStore();
          router.push({ name: "SignIn" });
        } else {
          handleError("Failed to logout.");
        }
      } catch (error: unknown) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            // Handle the unauthorized case (logout)
            programsStore.clearStore();
            authStore.clearStore();
            router.push({
              name: "SignIn",
              query: { error: "Something went wrong." },
            });
          } else {
            handleError(
              error.response?.data?.message ||
                error.message ||
                "Failed to logout."
            );
          }
        } else if (error instanceof Error) {
          handleError(error.message);
        } else {
          handleError("An unknown error occurred.");
        }
      }
    };

    const handleError = (error: string) => {
      errorMessage.value = error;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    const handleClickOutside = (event: MouseEvent) => {
      const dropdownMenu = document.querySelector("#userProfile");
      const logoutButton = document.querySelector(".account-options-btns");

      if (
        showDropdown.value &&
        dropdownMenu &&
        !dropdownMenu.contains(event.target as Node) &&
        (!logoutButton || !logoutButton.contains(event.target as Node))
      ) {
        showDropdown.value = false;
      }
    };

    const initials = () => {
      if (authStore.firstName && authStore.lastName) {
        const firstInitial = authStore.firstName.charAt(0).toUpperCase();
        const lastInitial = authStore.lastName.charAt(0).toUpperCase();
        return `${firstInitial}${lastInitial}`;
      }
      return "";
    };

    const goToProfile = () => {
      router.push({ name: "ExpertProfile" });
      // console.log("Coming Soon");
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    const email = computed(() => {
      return authStore.email;
    });

    const profilePictureUrl = computed(() => {
      return `${
        authStore?.profileInformation.profilePicture
      }?t=${new Date().getTime()}`;
    });

    return {
      COMPANY_LOGO,
      EARNINGS_ICON,
      EXPERTISE_ICON,
      DASHBOARD_ICON,
      LOGOUT_ICON,
      PERSON_ICON,
      navigateToDashboard,
      navigateToExpertise,
      navigateToEarning,
      authStore,
      logout,
      toggleDropdown,
      showDropdown,
      disableLogout,
      errorMessage,
      email,
      initials,
      goToProfile,
      profilePictureUrl,
    };
  },
});
</script>
