<template>
  <div class="flex overflow-hidden flex-col pb-5 bg-white">
    <div
      v-if="isFetchingEarnings"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-999"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <header class="flex flex-col w-full border-b max-md:max-w-full">
      <ExpertiseNavbar :activeTab="2" />
    </header>
    <main
      class="flex flex-col items-start px-9 mt-10 w-full max-md:pl-5 max-md:max-w-full"
    >
      <h1 class="text-3xl font-semibold leading-tight text-black">
        My Earnings
      </h1>
      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <img
              src="../assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>
      <nav
        class="flex flex-wrap z-10 gap-8 justify-center items-center mt-6 text-sm font-medium leading-none whitespace-nowrap"
      >
        <NavBar
          v-for="item in navItems"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :activeTab="selectedTab"
          @update:activeTab="handleTabChange"
        />
      </nav>
      <div class="flex flex-col w-full" v-if="selectedTab === 'overview'">
        <div class="flex flex-col">
          <EarningsOverview
            :escrows="escrow"
            :withdrawBalance="withdrawBalance"
          />
        </div>
        <div class="flex flex-col" v-if="transactions.length > 0">
          <PaymentsHistory
            :transactions="transactions"
            :moreTransactions="moreTransactions"
            :loadMoreTransactions="loadMoreTransactions"
          />
        </div>
      </div>
      <div class="flex flex-col w-full" v-else>
        <PayoutSection />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ExpertiseNavbar from "@/components/expertise/ExpertiseNavbar.vue";
import PaymentsHistory from "@/components/earnings/PaymentsHistory.vue";
import NavBar from "@/components/earnings/NavBar.vue";
import { EARNINGS_ICON } from "@/assets/svg/expert-overview/svgConstants";
import EarningsOverview from "@/components/earnings/EarningsOverview.vue";
import PayoutSection from "@/components/earnings/PayoutSection.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { Escrow, Transaction, TransactionEdge } from "@/types/earnings/types";
import { useRoute, useRouter } from "vue-router";

const GET_EXPERT_EARNINGS = gql`
  query getExpertEarnings($first: Int!) {
    getExpertEarnings {
      escrows {
        availableBalance
        pendingBalance
        totalEarnings
      }
      transactions(first: $first) {
        edges {
          node {
            id
            amount
            activity
            description
            createdAt
            status
          }
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  }
`;

export default defineComponent({
  name: "ExperEarnings",
  components: {
    ExpertiseNavbar,
    PaymentsHistory,
    NavBar,
    EarningsOverview,
    PayoutSection,
  },
  setup() {
    const selectedTab = ref("overview");
    const records = ref(6);
    const escrow = ref<Escrow>();
    const transactions = ref<Transaction[]>([]);
    const moreTransactions = ref(false);
    const errorMessage = ref("");
    const route = useRoute();
    const router = useRouter();
    const isFetchingEarnings = ref(false);
    const navItems = [
      { label: "Overview", value: "overview" },
      { label: "Payout", value: "payout" },
    ];

    const handleTabChange = (value: string) => {
      selectedTab.value = value;
      if (value === "overview") {
        getEarnings(records.value);
      }
    };

    const getEarnings = (records: number) => {
      isFetchingEarnings.value = true;
      const { onResult, onError } = useQuery(
        GET_EXPERT_EARNINGS,
        {
          first: records,
        },
        {
          fetchPolicy: "network-only",
        }
      );

      onResult((result) => {
        console.log(result);
        if (result) {
          if (result?.data?.getExpertEarnings?.escrows) {
            console.log("Earnings ", result.data.getExpertEarnings);
            escrow.value = result.data.getExpertEarnings.escrows;
          }
          if (result?.data?.getExpertEarnings?.transactions?.edges) {
            // transactions.value = result.data.getExpertEarnings.transactions.edges;
            console.log(
              "transactions",
              result.data.getExpertEarnings.transactions.edges
            );

            transactions.value =
              result.data.getExpertEarnings.transactions.edges.map(
                (edge: TransactionEdge) => ({
                  id: edge.node.id,
                  amount: edge.node.amount,
                  activity: edge.node.activity,
                  description: edge.node.description,
                  status: edge.node.status,
                  createdAt: edge.node.createdAt,
                })
              );
            moreTransactions.value =
              result.data.getExpertEarnings.transactions.pageInfo.hasNextPage;
          }
          isFetchingEarnings.value = false;
        }
      });

      onError((error) => {
        if (error) {
          console.error(error);
          handleError(error.message);
          isFetchingEarnings.value = false;
        }
      });
    };

    const loadMoreTransactions = () => {
      records.value += 6;
      getEarnings(records.value);
    };

    const handleError = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 3500);
    };

    const withdrawBalance = () => {
      selectedTab.value = "payout";
    };

    const clearQueryParams = () => {
      const { fullPath } = route;
      const newUrl = fullPath.split("?")[0];
      router.replace({ path: newUrl });
    };

    onMounted(() => {
      if (route.query?.onboarded === "true") {
        selectedTab.value = "payout";
        // route
        clearQueryParams();
      } else {
        getEarnings(records.value);
      }
    });

    return {
      selectedTab,
      navItems,
      handleTabChange,
      EARNINGS_ICON,
      escrow,
      transactions,
      moreTransactions,
      loadMoreTransactions,
      errorMessage,
      withdrawBalance,
      isFetchingEarnings,
    };
  },
});
</script>
