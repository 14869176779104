import { getTimeZones } from "@vvo/tzdb";

type ProcessedTimezoneRecord = {
  name: string;
  utc: string;
  alternateName: string;
};

const priorityZones = [
  "Pacific Time",
  "Mountain Time",
  "Central Time",
  "Eastern Time",
];

export function processedTimezones(): ProcessedTimezoneRecord[] {
  const timezoneRecords = getTimeZones({ includeUtc: true });
  const seenAlternateNames = new Set<string>();
  const priorityList: ProcessedTimezoneRecord[] = [];
  const otherList: ProcessedTimezoneRecord[] = [];

  timezoneRecords.forEach((record) => {
    const alternateName = record.alternativeName || "";

    if (seenAlternateNames.has(alternateName)) {
      return;
    }

    const offsetInMinutes = record.currentTimeOffsetInMinutes;

    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const minutes = Math.abs(offsetInMinutes) % 60;

    const sign = offsetInMinutes >= 0 ? "+" : "-";

    const utc = `UTC${sign}${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;

    seenAlternateNames.add(alternateName);

    const processedRecord = {
      name: record.name,
      utc: utc,
      alternateName: `${alternateName} (${utc})`,
    };

    if (priorityZones.includes(alternateName)) {
      priorityList.push(processedRecord);
    } else {
      otherList.push(processedRecord);
    }
  });

  otherList.sort((a, b) => a.alternateName.localeCompare(b.alternateName));

  return [...priorityList, ...otherList];
}
